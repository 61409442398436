<template>
  <div style="width:100%; height: 100%; background-color: #fff">
    <iframe v-if="podId" :src="fullPath" height="99%" width="100%" style="border: none; background-color: #fff"></iframe>
  </div>
</template>

<script>
	export default {
		name: 'PublicRouter',

		props: ['accountKey', 'resourceId'],

		components: {},

		data: function() {
			return {};
		},

		mounted() {
      window.addEventListener("message", (event) => {
        if(event.data && event.data.startsWith('[Redirect]')){
          let url = event.data.slice(10);
          window.location = url;
        }
      }, false);
    },

		beforeDestroy() {},

		methods: {
      appendIfPresent: function(searchParams,key){
        if(this.$route.query[key]){
          searchParams.append(key,this.$route.query[key]);
        }
      }
    },

		computed: {
			fullPath: function() {
        let url = new URL(`${process.env.VUE_APP_MEET_LINK}${this.podId}/${this.accountKey}/${this.resourceId}`);
        url.searchParams.append("inPortal", "true");

        this.appendIfPresent(url.searchParams,'opportunityId');
        this.appendIfPresent(url.searchParams,'action');
        this.appendIfPresent(url.searchParams,'id');
        this.appendIfPresent(url.searchParams,'knownUser');
        this.appendIfPresent(url.searchParams,'firstName');
        this.appendIfPresent(url.searchParams,'lastName');
        this.appendIfPresent(url.searchParams,'email');
        this.appendIfPresent(url.searchParams,'phone');

        return url.href;
			},

			podId: function() {
				if (this.$store.state.domain) {
					let domain = new URL(this.$store.state.domain.podUrl).hostname;
					return domain.split('.')[0].replace(/\D/g, '');
				} else {
					return null;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
