<template>
	<div style="width: 100%; height: 100%" class="centered">
		<div class="brand-medium font-24" v-if="!isReady">{{ $t('invoices.payment.loading') }}</div>
		<v-container v-else fluid style="max-width: 800px" class="pa-12">
			<v-row dense>
        <v-col cols="12" class="text-center pt-10 pb-8">
          <v-img v-if="logo" :src="logo" max-height="100" contain></v-img>
        </v-col>
				<v-col cols="12" align="center">
					<div class="column-format" style="max-width: fit-content">
						<div class="brand-medium font-24">{{ $t('invoices.payment.options') }}</div>
						<div class="column-format gap-1 py-5">
							<div class="row-format" style="border-bottom: 1px solid var(--v-gray_30-base)">
								<div>{{ $t('invoices.list.invoice-number') }}:</div>
								<div class="ml-auto font-weight-bold">{{ paymentInfo.invoiceNumberFormatted }}</div>
							</div>
							<div class="row-format" style="border-bottom: 1px solid var(--v-gray_30-base)">
								<div>{{ $t('invoice.details.issued') }}:</div>
								<div class="ml-auto font-weight-bold">
									{{ DateTime.fromISO(paymentInfo.dateSent).toLocaleString(DateTime.DATE_MED) }}
								</div>
							</div>
							<div class="row-format" style="border-bottom: 1px solid var(--v-gray_30-base)">
								<div>{{ $t('invoice.details.due') }}:</div>
								<div class="ml-auto font-weight-bold">
									{{ DateTime.fromISO(paymentInfo.dateDue).toLocaleString(DateTime.DATE_MED) }}
								</div>
							</div>
							<div class="row-format" style="border-bottom: 1px solid var(--v-gray_30-base)">
								<div>{{ $t('invoice.details.total-due') }}:</div>
								<div class="ml-auto font-weight-bold">
									{{ $formatters.dollars(paymentInfo.invoiceAmount, true, true, paymentInfo.currency) }}
								</div>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col
					cols="12"
					v-if="achEnabled || paymentMethods.length"
					:sm="cardEnabled ? 6 : 12"
					:class="smallScreen || !cardEnabled ? 'border-bottom' : 'border-right'"
				>
					<div class="column-format" style="height: 100%">
						<h2>
							{{ $t('invoices.payment.ach') }}
							<v-icon
								class="material-symbols-outlined pointer"
								color="primary"
								size="20"
								@click="editSavedPaymentMethods"
								>edit</v-icon
							>
						</h2>
						<v-btn
							class="primary-action my-1"
							@click="confirmPayment(account)"
							v-for="account in paymentMethods"
							:key="account.id"
							>{{ $t('invoices.payment.pay-with') }} {{ account.label }} - {{ account.last4 }}</v-btn
						>
						<div v-if="paymentMethods.length === 0" class="my-3">
							{{ $t('invoices.payment.no-linked-accounts') }}
						</div>
						<div class="pointer my-1" @click="initializeACHLink" v-if="achEnabled">
							<v-icon small color="primary" class="mr-1">$plus</v-icon
							><span class="brand-medium" style="color: var(--v-primary-base)">{{
								$t('invoices.payment.new-linked')
							}}</span>
						</div>
						<div class="pt-4 mt-auto font-12" style="font-style: italic">
							<v-icon small class="mb-1">$stripe</v-icon>{{ $t('invoices.payment.stripe') }}
						</div>
					</div>
				</v-col>
				<v-col
					cols="12"
					:sm="achEnabled || paymentMethods.length ? 6 : 12"
					v-if="cardEnabled"
					:class="smallScreen || !cardEnabled ? 'border-bottom' : ''"
				>
					<div class="column-format" style="height: 100%">
						<h2>{{ $t('invoices.payment.payment-options') }}</h2>
						<div class="pointer payment-container my-1" @click="initiateStripeCheckout()">
							<p class="mt-3">
								<span class="mr-2"><payment-icon size="16" name="amex"></payment-icon></span>
								<span class="mr-2"><payment-icon size="16" name="master_card"></payment-icon></span>
								<span class="mr-2"><payment-icon size="16" name="visa"></payment-icon></span>
								<span class="mr-2"><payment-icon size="16" name="discover"></payment-icon></span>
							</p>
							<p>
								<span class="mr-2"><v-icon color="black">$apple</v-icon></span>
								{{ $t('invoices.payment.apple-pay') }}
							</p>
							<p>
								<span class="mr-2"><v-icon color="black">$google</v-icon></span
								>{{ $t('invoices.payment.google-pay') }}
							</p>
						</div>
						<div class="pt-4 mt-auto font-12" style="font-style: italic">
							<v-icon small class="mb-1">$stripe</v-icon>{{ $t('invoices.payment.stripe') }}
						</div>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="mt-4" id="paypal-button-container"></div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import qs from 'qs';
	import HttpClient from '@/services/HttpClient';
	import PaymentIcon from '@/modules/invoices/InvoiceRenderer/PaymentIcon';
	import ConfirmModal from '@/components/ConfirmModal';
	import { loadScript } from '@paypal/paypal-js';
	import { DateTime } from 'luxon';

	export default {
		name: 'Payment',

		props: [],

		components: { PaymentIcon },

		data: function() {
			return {
				DateTime: DateTime,
				httpClient: null,
				token: null,
				paymentInfo: null,
				paypalConnection: null,
				isReady: false,
				paymentIntent: null,
			};
		},

		mounted() {
			let queryString = qs.parse(self.location.search.slice(1));
			let request = JSON.parse(atob(queryString.token.split('.')[0]));

			let podUrl = request.podUrl;

			this.httpClient = new HttpClient(podUrl, false);
			this.token = queryString.token;

			this.paymentIntent = queryString.payment_intent;
			this.initialize();
		},

		beforeDestroy() {},

		methods: {
			initialize: function() {
				this.getPaymentMethods().then((res) => {
					this.paymentInfo = res.data;
					this.paypalConnection = this.paymentInfo.payPalConnection;

					if (this.paymentIntent) {
						this.$store.commit('startLoading');
						this.finalizePaymentIntent()
							.then((res) => {
								setTimeout(() => {
									window.location.href = res.data;
								}, 1000);
							})
							.catch((err) => {
								this.$store.commit('stopLoading');
								if (err.response.status === 418) {
                  this.handleSuccess(err.response.data.message, this.paymentInfo.viewOnlineUrl);
								} else {
									this.handleError(err.response.data.message);
								}
							});
					} else {
						if (this.paymentInfo.invoiceAmount === 0) {
							this.handleError('This invoice does not currently require payment', this.paymentInfo.returnUrl, true);
							return;
						}

						if (
							!this.paymentIntent &&
							this.paymentInfo.cardPaymentsEnabled &&
							this.paymentInfo.paymentMethods.length === 0 &&
							!this.paymentInfo.achPaymentsEnabled &&
							!this.paymentInfo.payPalPaymentsEnabled
						) {
							this.initiateStripeCheckout();
						} else {
							this.isReady = true;
							if (this.paymentInfo.payPalPaymentsEnabled) {
								this.$nextTick(() => this.initializePayPal());
							}
						}
					}
				}).catch((err) => {
          if(err.response?.data.detail){
            window.location.href = err.response.data.detail;
          }else {
            this.handleError('Error ', err.response?.data?.message, false);
          }
        });
			},

			editSavedPaymentMethods: function() {
				return this.httpClient
					.get('/api/docs/invoice/managePaymentMethods', {
						params: {
							token: this.token,
							redirectUri: location.href,
						},
					})
					.then((res) => {
						document.location.href = res.data;
					})
					.catch((err) => this.handleError(err.response.data.message));
			},

			initiateStripeCheckout: function() {
				this.$store.commit('startLoading');
				this.getStripeCheckoutByToken().then((res) => {
					this.$store.commit('stopLoading');
					let stripe = window.Stripe(this.$store.state.stripeKey, {
						stripeAccount: res.data.stripeAccountId,
					});

					stripe.redirectToCheckout({
						sessionId: res.data.checkoutSessionId,
					});
				});
			},

			initializePayPal: function() {
				let clientId, attributionId;
				if (this.paypalConnection.version === 'STANDARD') {
					console.log('Standard connection detected. ' + this.paypalConnection.clientId);
					clientId = this.paypalConnection.clientId;
					attributionId = null;
				} else {
					console.log('Partner connection detected. ' + this.$store.state.payPalClientId);
					clientId = this.$store.state.payPalClientId;
					attributionId = 'StruxtureTechnologiesInc_SP_PPCP';
				}

				loadScript({
					'client-id': clientId,
					currency: this.paymentInfo.currency,
					'merchant-id': [this.paymentInfo.payPalConnection.merchantId],
					'data-partner-attribution-id': attributionId,
				}).then((paypal) => {
					paypal
						.Buttons({
							createOrder: (data, actions) => {
								return actions.order.create({
									purchase_units: [
										{
											custom_id: this.paymentInfo.invoiceId,
											invoice_id: this.paymentInfo.invoiceNumberFormatted,
											amount: {
												value: this.paymentInfo.invoiceAmount,
											},
										},
									],
								});
							},

							onApprove: (data, actions) => {
								return actions.order.capture().then((orderData) => {
									let status = orderData.status;
									let id = orderData.id;

									if (status === 'COMPLETED') {
										this.$store.commit('startLoading');
										let href = orderData.links.filter((l) => l.rel === 'self')[0].href;
										this.capturePayPalPayment(href)
											.then((res) => {
												this.$store.commit('stopLoading');
												window.location.href = res.data;
											})
											.catch((err) => {
												this.$store.commit('stopLoading');
												this.handleError(err.response.data.message, this.paymentInfo.returnUrl);
											});
									} else {
										this.handleError(
											id + ':' + this.$t('error.order-not-complete') + status,
											this.paymentInfo.returnUrl
										);
									}
								});
							},

							onError: (err) => {
								this.handleError(err.message);
							},
						})
						.render('#paypal-button-container')
						.catch((error) => {
							console.error('failed to render the PayPal Buttons', error);
						});
				});
			},

			getPaymentMethods: function() {
				return this.httpClient
					.get('/api/docs/invoice/paymentInfo', {
						params: {
							token: this.token,
						},
					})
					.then((response) => Promise.resolve(response))
					.catch((error) => Promise.reject(error));
			},

			finalizePaymentIntent: function() {
				return this.httpClient
					.post('/api/docs/invoice/payment/intent', null, {
						params: {
							token: this.token,
							paymentIntent: this.paymentIntent,
						},
					})
					.then((response) => Promise.resolve(response))
					.catch((error) => Promise.reject(error));
			},

			capturePayPalPayment: function(href) {
				return this.httpClient
					.post('/api/docs/invoice/payPalCapture', null, {
						params: {
							token: this.token,
							href: href,
						},
					})
					.then((response) => Promise.resolve(response))
					.catch((error) => Promise.reject(error));
			},

			getStripeCheckoutByToken: function() {
				return this.httpClient
					.get('/api/docs/invoice/payment', {
						params: {
							token: this.token,
						},
					})
					.then((response) => Promise.resolve(response))
					.catch((error) => Promise.reject(error));
			},

			initializeACHLink: function() {
				this.$store.commit('startLoading');
				return this.httpClient
					.post('/api/docs/invoice/bankAccounts/session', null, {
						params: {
							token: this.token,
							redirectUri: window.location.href,
						},
					})
					.then((res) => {
						window.location.href = res.data.linkUri;
					})
					.catch((error) => console.log(error))
					.finally(() => this.$store.commit('stopLoading'));
			},

			confirmPayment(account) {
				console.log(this.$t('invoices.payment.confirm'));

				let prompt;
				let total = 0;

				if (this.paymentInfo.stripeCardFeeRate && account.type === 'card') {
					let feeLabel = Math.round(this.paymentInfo.stripeCardFeeRate * 100 * 100) / 100 + '%';
					let feeAmount = this.paymentInfo.invoiceAmount * this.paymentInfo.stripeCardFeeRate;
					total = this.paymentInfo.invoiceAmount + feeAmount;
					prompt = `Please confirm your payment of ${this.$formatters.dollars(
						total,
						true,
						true,
						this.paymentInfo.currency
					)}, which includes a ${this.$formatters.dollars(
						feeAmount,
						true,
						true,
						this.paymentInfo.currency
					)} (${feeLabel}) processing fee, from account ending in ${account.last4}.`;
				} else {
					total = this.paymentInfo.invoiceAmount;
					prompt = `Please confirm your payment of ${this.$formatters.dollars(
						total,
						true,
						true,
						this.paymentInfo.currency
					)} from account ending in ${account.last4}.`;
				}

				let binding = {
					severity: 'primary',
					headingText: this.$t('invoices.payment.confirm'),
					bodyText: prompt,
				};

				this.$store.state.globalModalController.openModal(ConfirmModal, binding, false, false).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.httpClient
							.post('/api/docs/invoice/payment', null, {
								params: {
									token: this.token,
									amount: total,
									paymentMethodId: account.id,
								},
							})
							.then((res) => {
								setTimeout(() => {
									window.location.href = res.data;
								}, 2000);
							})
							.catch((err) => {
								this.$store.commit('stopLoading');
								if (err.response.status === 412) {
									this.handle3DSRedirect(err.response.data.message);
								} else {
									this.handleError(err.response.data.message);
								}
							});
					}
				});
			},

			handle3DSRedirect: function(url) {
				window.location.href = url;
			},

			handleError: function(message, returnUrl, doClose = false) {
				let binding = {
					headingText: this.$t('error.connecting-account'),
					bodyText: message,
					hideNo: true,
					yesText: this.$t('global.okay'),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding, true, false).then(() => {
					if (returnUrl) {
						window.location.href = returnUrl;
					} else if (doClose) {
						window.self.close();
					}
				});
			},

			handleSuccess: function(message, returnUrl) {
				let binding = {
					icon: 'done',
					headingText: 'Success',
					bodyText: message,
					hideNo: true,
					yesText: this.$t('global.okay'),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding, true, false).then(() => {
					window.location.href = returnUrl;
				});
			},
		},

		computed: {
			smallScreen: function() {
				return this.$vuetify.breakpoint.xsOnly;
			},

			achEnabled: function() {
				if (this.paymentInfo && this.paymentInfo.achPaymentsEnabled) {
					return true;
				} else {
					return false;
				}
			},

			cardEnabled: function() {
				if (this.paymentInfo && this.paymentInfo.cardPaymentsEnabled) {
					return true;
				} else {
					return false;
				}
			},

			paymentMethods: function() {
				if (this.paymentInfo.paymentMethods) {
					return this.paymentInfo.paymentMethods.filter((a) => a.status === 'verified');
				} else {
					return [];
				}
			},

      logo: function() {
        if(this.paymentInfo){
          return this.paymentInfo.accountLogo;
        }else{
          return null;
        }
      },
		},
	};
</script>

<style scoped lang="scss">
	.payment-container {
		padding: 4px 12px;
		border-radius: 4px;
		border: 1px solid var(--v-white-base);
		&:hover {
			border: 1px solid var(--v-gray_30-base);
			background-color: var(--v-gray_10-base);
		}
	}

	.border-right {
		border-right: 1px solid var(--v-gray_50-base);
	}

	.border-bottom {
		border-bottom: 1px solid var(--v-gray_50-base);
	}
</style>
